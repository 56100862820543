import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import moment from 'moment';
import infiniteScroll from 'vue-infinite-scroll';
import { URLS } from '@/common/js/constant/index';
import DatePicker from '../../_modules/mobile/date-picker.vue';
import Foot from '@/views/_modules/mobile/footer.vue';
let Redemption = class Redemption extends Vue {
    constructor() {
        super(...arguments);
        this.moment = moment;
        this.date = [
            moment().subtract(30, 'day').format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD'),
        ];
        this.statisticsInfo = {
            total_ticket_count: 0,
            total_unit_count: 0,
        };
        this.isStatisticsLoading = false;
        this.isListLoading = false;
        this.isShowDateRangeDrawer = false;
        this.list = [];
        this.pagination = {
            total: 0,
            current: 1,
            defaultPageSize: 10,
        };
        this.isListEnd = false;
        this.dateCritical = {
            min: '',
            max: new Date(),
        };
    }
    processListEnd() {
        const { total, current, defaultPageSize } = this.pagination;
        this.isListEnd = current * defaultPageSize >= total;
    }
    // 获取兑换数据
    getRedeemStatistics() {
        this.isStatisticsLoading = true;
        this.$ajax
            .get(URLS.getRedeemStatistics, {
            from: this.date[0],
            to: this.date[1],
        })
            .then((res) => {
            this.statisticsInfo = res;
            this.isStatisticsLoading = false;
        });
    }
    // 获取兑换列表数据
    getRedeemList() {
        if (this.isListEnd || this.isListLoading) {
            return;
        }
        this.isListLoading = true;
        this.$ajax
            .get(URLS.getRedeemList, {
            from: this.date[0],
            to: this.date[1],
            page: this.pagination.current,
            limit: this.pagination.defaultPageSize,
        })
            .then((res) => {
            this.list = this.list.concat(this.processList(res.redeem_infos || []));
            this.pagination.total = res.total;
            this.isListLoading = false;
            this.processListEnd();
            this.pagination.current++;
        });
    }
    /**
     * 处理list，以兑换日期来分组
     * @param {Array} list 原始数据
     * @return {Array} 兑换日期合并的表格数据
     */
    processList(list) {
        const result = {};
        const lastDate = this.list.length && this.list[this.list.length - 1].date;
        list.forEach((item) => {
            const redeemDate = moment(item.redeem_time).format('YYYY-MM-DD');
            Object.assign(item, {
                redeem_time: moment(item.redeem_time).format('HH:mm:ss'),
            });
            // 如果列表已有相同的日期则直接加进去
            if (lastDate === redeemDate) {
                this.list[this.list.length - 1].list.push(item);
            }
            else {
                // 否则新开新的日期
                if (!result[redeemDate]) {
                    result[redeemDate] = {
                        date: redeemDate,
                        list: [],
                    };
                }
                result[redeemDate].list.push(item);
            }
        });
        return Object.values(result);
    }
    /**
     * 时间发生改变
     * @param {date} 选中的时间值
     */
    onDateChanged(date) {
        if (date.length === 1) {
            const minDate = moment(date[0]).subtract(90, 'day');
            const maxDate = moment(date[0]).add(90, 'day');
            const today = new Date();
            this.dateCritical.min = minDate.format('YYYY-MM-DD');
            this.dateCritical.max = maxDate.isBefore(today) ? maxDate.format('YYYY-MM-DD') : today;
        }
        else {
            this.date = date.map((item) => moment(item).format('YYYY-MM-DD'));
            this.dateCritical = {
                min: '',
                max: new Date(),
            };
            this.isShowDateRangeDrawer = false;
            this.list = [];
            this.pagination.current = 1;
            this.isListEnd = false;
            this.getRedeemStatistics();
            this.getRedeemList();
        }
    }
    created() {
        this.getRedeemStatistics();
    }
};
Redemption = __decorate([
    Component({
        components: {
            DatePicker,
            Foot,
        },
        directives: {
            infiniteScroll,
        },
    })
], Redemption);
export default Redemption;
