import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import moment from 'moment';
import { URLS } from '@/common/js/constant/index';
let Redemption = class Redemption extends Vue {
    constructor() {
        super(...arguments);
        this.dateTemp = {};
        this.moment = moment;
        this.date = [moment().subtract(30, 'day'), moment()];
        this.statisticsInfo = {
            total_ticket_count: 0,
            total_unit_count: 0,
        };
        this.isStatisticsLoading = false;
        this.isListLoading = false;
        this.pagination = {
            total: 0,
            current: 1,
            defaultPageSize: 10,
        };
        this.list = [];
    }
    created() {
        this.getRedeemStatistics();
        this.getRedeemList();
    }
    /**
     * 禁用日期
     * @param {Object} current 面板当前时间
     */
    disabledDate(current) {
        let result = false;
        const selectedDate = this.dateTemp;
        const isEmpty = JSON.stringify(selectedDate) === '{}';
        // 最晚选择当天
        if (current > moment().endOf('day')) {
            result = true;
        }
        // 只能选中三个月
        if (!isEmpty) {
            if (current < moment(selectedDate).subtract(90, 'day')) {
                result = true;
            }
            if (current > moment(selectedDate).add(90, 'day')) {
                result = true;
            }
        }
        return result;
    }
    /**
     * 日期面板选中
     * @param {Array} dates 日期数组
     */
    calendarChanged(dates) {
        if (dates.length === 1) {
            this.dateTemp = dates[0];
        }
    }
    /**
     * 日期重置
     * @param {Array} dates 日期数组
     */
    onDateChanged(dates) {
        this.date = dates;
        this.getRedeemStatistics();
        this.getRedeemList();
    }
    // 导出订单
    exportExcel() {
        const from = this.date[0];
        const to = this.date[1];
        const isInOneMonth = moment(KLOOK.clone(from))
            .add(31, 'day')
            .isAfter(to);
        if (isInOneMonth) {
            KLOOK.downloadFile(`${URLS.exportRedeemList}?from=${from.format('YYYY-MM-DD')}&to=${to.format('YYYY-MM-DD')}`);
        }
        else {
            this.$message.warning(this.$t('redemption.date_range_warning'));
        }
    }
    // 获取兑换数据
    getRedeemStatistics() {
        this.isStatisticsLoading = true;
        this.$ajax
            .get(URLS.getRedeemStatistics, {
            from: this.date[0].format('YYYY-MM-DD'),
            to: this.date[1].format('YYYY-MM-DD'),
        })
            .then((res) => {
            this.statisticsInfo = res;
            this.isStatisticsLoading = false;
        });
    }
    // 获取兑换列表数据
    getRedeemList() {
        this.isListLoading = true;
        this.$ajax
            .get(URLS.getRedeemList, {
            from: this.date[0].format('YYYY-MM-DD'),
            to: this.date[1].format('YYYY-MM-DD'),
            page: this.pagination.current,
            limit: this.pagination.defaultPageSize,
        })
            .then((res) => {
            this.list = res.redeem_infos || [];
            this.pagination.total = res.total;
            this.isListLoading = false;
            this.$updateInnerTable();
        });
    }
    /**
     * 页码改变事件
     * @param {Object} pagination 分页数据
     */
    onPaginationChanged(pagination) {
        this.pagination.current = pagination.current;
        this.getRedeemList();
    }
};
Redemption = __decorate([
    Component
], Redemption);
export default Redemption;
