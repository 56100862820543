import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import LoginWithCode from './login-with-code.vue';
import LoginWithPwd from './login-with-pwd.vue';
let Login = class Login extends Vue {
    constructor() {
        super(...arguments);
        this.formRules = {};
        this.form = {
            login_type: 'email',
            username: '',
            password: '',
        };
        this.isRememberMe = false;
        this.isLoading = false;
        this.loginMethod = 'pwd';
    }
    get isZhCN() {
        return KLOOK.getLocale() === 'zh-CN';
    }
    /**
     * 登录方式切换
     */
    switchLoginMethod() {
        this.loginMethod = this.loginMethod === 'pwd' ? 'code' : 'pwd';
    }
};
Login = __decorate([
    Component({
        components: {
            LoginWithCode,
            LoginWithPwd,
        },
    })
], Login);
export default Login;
