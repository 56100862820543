import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import CodeVerifier from '../_common/code-verifier.vue';
let Login = class Login extends Vue {
    constructor() {
        super(...arguments);
        this.form = {
            login_type: 'email',
            username: '',
        };
        this.isLoading = false;
        this.errorText = '';
    }
    created() {
        const query = this.$route.query;
        this.form = {
            login_type: query.type || this.form.login_type,
            username: query.username || this.form.username,
        };
    }
    /**
     * 登录类型发生改变
     * @param {String} val 当前类型
     */
    onLoginTypeChanged(val) {
        this.form = {
            login_type: val,
            username: '',
        };
        // form.resetFields();
    }
    // 提交
    submit() {
        const codeVerifier = this.$refs.codeVerifier;
        codeVerifier
            .verify()
            .then((res) => {
            const token = res.token;
            this.$router.push({
                name: 'setPassword',
                query: {
                    token,
                },
            });
        });
    }
};
Login = __decorate([
    Component({
        components: {
            CodeVerifier,
        },
    })
], Login);
export default Login;
