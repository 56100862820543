import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import KlookHeader from '../../desktop/header.vue';
let Layout = class Layout extends Vue {
    get isSimpleStyle() {
        const hiddenRoutes = ['home'];
        const curRoute = this.$route.name || '';
        return hiddenRoutes.includes(curRoute);
    }
};
Layout = __decorate([
    Component({
        components: {
            KlookHeader,
        },
    })
], Layout);
export default Layout;
