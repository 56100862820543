import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { accountRules } from '@/common/js/form-rule/index';
import { URLS } from '@/common/js/constant/index';
import Cookie from 'js-cookie';
let Login = class Login extends Vue {
    constructor() {
        super(...arguments);
        this.formRules = {};
        this.form = {
            login_type: 'email',
            username: '',
            password: '',
        };
        this.isRememberMe = false;
        this.isLoading = false;
        this.temp = {
            email: '',
            phone: '',
            account: '',
        };
    }
    created() {
        const loginInfo = KLOOK.LS.get('loginInfo');
        if (loginInfo) {
            this.isRememberMe = true;
            this.form = JSON.parse(loginInfo);
        }
        this.bindRules();
    }
    bindRules() {
        this.formRules = accountRules.call(null, this.form);
    }
    get isZhCN() {
        return KLOOK.getLocale() === 'zh-CN';
    }
    /**
     * 登录类型发生改变
     * @param {String} val 当前类型
     */
    onLoginTypeChanged(val) {
        const curType = this.form.login_type;
        this.temp[curType] = this.form.username;
        const form = this.$refs.form;
        this.form.login_type = val;
        form.resetFields();
        this.form.username = this.temp[val];
    }
    // 处理记住账密码
    handleRememberMe() {
        if (this.isRememberMe) {
            KLOOK.LS.set('loginInfo', JSON.stringify(Object.assign(this.form, {
                password: '',
            })));
        }
        else {
            KLOOK.LS.remove('loginInfo');
        }
    }
    // 登录
    login() {
        const form = this.$refs.form;
        form.validate((valid) => {
            if (valid) {
                this.isLoading = true;
                this.$ajax.post(URLS.login, {
                    login_type: this.form.login_type,
                    username: this.form.username,
                    password: KLOOK.md5(this.form.password),
                }).then((res) => {
                    Cookie.set('isLogin', '1', {
                        path: '/',
                        expires: 5,
                    });
                    this.handleRememberMe();
                    this.$router.push({ name: 'home' });
                }).catch(() => {
                    this.isLoading = false;
                });
            }
        });
    }
};
Login = __decorate([
    Component
], Login);
export default Login;
