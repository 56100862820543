import { __decorate } from "tslib";
import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { accountRules } from '@/common/js/form-rule/index';
import { URLS } from '@/common/js/constant/index';
let DialogResetPassword = class DialogResetPassword extends Vue {
    constructor() {
        super(...arguments);
        this.form = {
            password: '',
            confirm_password: '',
        };
        this.formRules = {};
        this.isLoading = false;
    }
    onShowChanged(val) {
        if (!val) {
            const form = this.$refs.form;
            form.resetFields();
        }
    }
    created() {
        this.formRules = accountRules.call(null, this.form);
    }
    save() {
        const form = this.$refs.form;
        form.validate((valid) => {
            if (valid) {
                this.isLoading = true;
                this.$ajax.post(URLS.resetPasswordByGuid, {
                    user_guid: this.info.user_guid,
                    new_password: KLOOK.md5(this.form.password),
                }).then((res) => {
                    this.$successTips();
                    this.isShowDialog = false;
                    this.$emit('updated');
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        });
    }
};
__decorate([
    Prop({ type: Object, default: () => ({}) })
], DialogResetPassword.prototype, "info", void 0);
__decorate([
    PropSync('show', { type: Boolean })
], DialogResetPassword.prototype, "isShowDialog", void 0);
__decorate([
    Watch('show')
], DialogResetPassword.prototype, "onShowChanged", null);
DialogResetPassword = __decorate([
    Component
], DialogResetPassword);
export default DialogResetPassword;
