import { __decorate } from "tslib";
import { Vue, Component, PropSync, Watch } from 'vue-property-decorator';
import { BrowserQRCodeReader } from '@zxing/library';
let RedeemVoucherScan = class RedeemVoucherScan extends Vue {
    constructor() {
        super(...arguments);
        this.QRCodeReader = '';
        this.enableShowErr = true;
    }
    onShowChanged(val) {
        if (val) {
            this.enableShowErr = true;
            this.initScanner();
        }
        else {
            this.QRCodeReader.reset();
        }
    }
    initScanner() {
        const scanner = this.$refs.scanner;
        this.QRCodeReader = new BrowserQRCodeReader();
        this.QRCodeReader.decodeFromInputVideoDevice(undefined, scanner)
            .then((result) => {
            this.isShowScanner = false;
            this.$emit('success', result.text);
        }).catch(() => {
            if (this.enableShowErr) {
                const self = this;
                this.$error({
                    content: 'Browser version is too old, please switch a new browser or upgrade your browser version.',
                    onOk() {
                        self.isShowScanner = false;
                    },
                });
            }
        });
    }
    close() {
        this.enableShowErr = false;
        this.isShowScanner = false;
    }
};
__decorate([
    PropSync('show', { type: Boolean })
], RedeemVoucherScan.prototype, "isShowScanner", void 0);
__decorate([
    Watch('show')
], RedeemVoucherScan.prototype, "onShowChanged", null);
RedeemVoucherScan = __decorate([
    Component
], RedeemVoucherScan);
export default RedeemVoucherScan;
