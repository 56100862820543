import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { accountRules } from '@/common/js/form-rule/index';
import { URLS } from '@/common/js/constant/index';
let Settings = class Settings extends Vue {
    constructor() {
        super(...arguments);
        this.formRules = {};
        this.form = {
            token: '',
            token_type: '',
            login_type: '',
            username: '',
            new_password: '',
            confirm_password: '',
        };
        this.isLoading = false;
    }
    get formTitle() {
        return this.form.token_type === 'active' ?
            this.$t('set_password.set_password')
            : this.$t('set_password.reset_password');
    }
    created() {
        Object.assign(this.form, this.$route.query);
        this.bindRules();
    }
    bindRules() {
        this.formRules = accountRules.call(null, this.form);
    }
    // 提交
    submit() {
        const form = this.$refs.form;
        form.validate((valid) => {
            if (valid) {
                this.isLoading = true;
                this.$ajax.post(URLS.resetPassword, {
                    token: this.form.token,
                    new_password: KLOOK.md5(this.form.new_password),
                }).then(() => {
                    this.$successTips();
                    this.$router.push({ name: 'login' });
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        });
    }
};
Settings = __decorate([
    Component
], Settings);
export default Settings;
