import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { NAV_LIST } from '@/common/js/constant/index.ts';
let MobileNav = class MobileNav extends Vue {
    constructor() {
        super(...arguments);
        this.NAV_LIST = NAV_LIST.filter((item) => item.platform.includes('mobile'));
    }
    get curNav() {
        const routeList = this.$route.matched;
        // 从本路由开始向上级匹配路由菜单
        for (const route of routeList) {
            for (const nav of this.NAV_LIST) {
                if (nav.name === route.name) {
                    return nav.name;
                }
            }
        }
        return '';
    }
};
MobileNav = __decorate([
    Component
], MobileNav);
export default MobileNav;
