import { __decorate } from "tslib";
import { VuexModule, Module, Mutation } from 'vuex-module-decorators';
let User = class User extends VuexModule {
    constructor() {
        super(...arguments);
        this.user_guid = '';
        this.username = '';
        this.user_type = '';
        this.counter_name = '';
    }
    updateUserInfo(payload) {
        this.user_guid = payload.user_guid || this.user_guid;
        this.username = payload.username || this.username;
        this.user_type = payload.user_type || this.user_type;
        this.counter_name = payload.counter_name || this.counter_name;
    }
    cleanUserInfo() {
        this.user_guid = '';
        this.username = '';
        this.user_type = '';
        this.counter_name = '';
    }
};
__decorate([
    Mutation
], User.prototype, "updateUserInfo", null);
__decorate([
    Mutation
], User.prototype, "cleanUserInfo", null);
User = __decorate([
    Module({ namespaced: true, name: 'user' })
], User);
export default User;
