import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { LANGUAGE_LIST } from '@/common/js/constant/index.ts';
let LangSelector = class LangSelector extends Vue {
    constructor() {
        super(...arguments);
        this.dataTrackEventCommon = 'Common Page|System Laguage|';
        this.LANGUAGE_LIST = LANGUAGE_LIST;
    }
    // 切换语言
    changeLang(lang) {
        if (lang === KLOOK.getLocale()) {
            return;
        }
        const url = (lang === 'en' ? '' : `/${lang}`) + this.$route.path;
        let queryString = Object.keys(this.$route.query).map((key) => `${key}=${this.$route.query[key]}`).join('&');
        queryString = queryString ? `?${queryString}` : '';
        window.location.replace(url + queryString);
    }
};
LangSelector = __decorate([
    Component
], LangSelector);
export default LangSelector;
