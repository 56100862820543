import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { URLS } from '@/common/js/constant/index';
import DialogResetPassword from './dialog-reset-password.vue';
import DialogAddAccount from './dialog-add-account.vue';
let Login = class Login extends Vue {
    constructor() {
        super(...arguments);
        this.formRules = {};
        this.list = [];
        this.isListLoading = false;
        this.pagination = {
            total: 0,
            current: 1,
            defaultPageSize: 10,
        };
        this.operateInfo = {};
        this.isShowAddDialog = false;
        this.isShowResetPwdDialog = false;
    }
    created() {
        this.getUserList();
    }
    // 获取账号列表
    getUserList() {
        this.isListLoading = true;
        this.$ajax.get(URLS.getUserList, {
            page: this.pagination.current,
            limit: this.pagination.defaultPageSize,
        }).then((res) => {
            this.list = res.user_list || [];
            this.pagination.total = res.total;
            this.isListLoading = false;
        });
    }
    /**
     * 锁定账号
     * @param {Object} info 用户信息
     */
    lock(info) {
        this.$confirm({
            class: 'modal-no-icon-title',
            content: this.renderTips('manage_account.lock_tips', info.username),
            onOk: () => {
                return new Promise((resolve, reject) => {
                    this.$ajax.post(URLS.lockUser, {
                        user_guid: info.user_guid,
                    }).then((res) => {
                        resolve();
                        this.$successTips();
                        this.getUserList();
                    }).catch(() => {
                        reject();
                    });
                });
            },
        });
    }
    /**
     * 解锁账号
     * @param {Object} info 用户信息
     */
    unlock(info) {
        this.$confirm({
            class: 'modal-no-icon-title',
            content: this.renderTips('manage_account.unlock_tips', info.username),
            onOk: () => {
                return new Promise((resolve, reject) => {
                    this.$ajax.post(URLS.unlockUser, {
                        user_guid: info.user_guid,
                    }).then((res) => {
                        resolve();
                        this.$successTips();
                        this.getUserList();
                    }).catch(() => {
                        reject();
                    });
                });
            },
        });
    }
    /**
     * 重发激活信息
     * @param {Object} info 用户信息
     */
    resendMessage(info) {
        this.$confirm({
            class: 'modal-no-icon-title',
            content: this.renderTips('manage_account.resend_message_tips', info.username),
            onOk: () => {
                return new Promise((resolve, reject) => {
                    this.$ajax.post(URLS.resendActiveMessage, {
                        user_guid: info.user_guid,
                    }).then((res) => {
                        resolve();
                        this.$successTips();
                    }).catch(() => {
                        reject();
                    });
                });
            },
        });
    }
    /**
     * 删除账号
     * @param {Object} info 用户信息
     */
    deleteUser(info) {
        const h = this.$createElement;
        this.$confirm({
            class: 'modal-no-icon-title',
            content: this.renderTips('manage_account.delete_tips', info.username),
            okType: 'danger',
            okText: this.$t('global.delete'),
            onOk: () => {
                return new Promise((resolve, reject) => {
                    this.$ajax.post(URLS.deleteUser, {
                        user_guid: info.user_guid,
                    }).then((res) => {
                        resolve();
                        this.$successTips();
                        this.getUserList();
                    }).catch(() => {
                        reject();
                    });
                });
            },
        });
    }
    /**
     * 重置密码
     * @param {Object} info 用户信息
     */
    reset(info) {
        this.operateInfo = info;
        this.isShowResetPwdDialog = true;
    }
    /**
     * 页码改变事件
     * @param {Object} pagination 分页数据
     */
    onPaginationChanged(pagination) {
        this.pagination.current = pagination.current;
        this.getUserList();
    }
    /**
     * 渲染提示
     * @param {string} key 提示多语言key
     * @param {srting} username 用户名
     */
    renderTips(key, name) {
        const h = this.$createElement;
        return h('span', {
            domProps: {
                innerHTML: this.$t(key, {
                    name: '<span class="operate-tips-name">' + name + '</span>',
                }),
            },
        });
    }
};
Login = __decorate([
    Component({
        components: {
            DialogResetPassword,
            DialogAddAccount,
        },
    })
], Login);
export default Login;
