import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import Cookie from 'js-cookie';
import { namespace } from 'vuex-class';
import { URLS } from '@/common/js/constant/index.ts';
import KlookNav from './nav.vue';
import KlookLangSelector from './lang-selector.vue';
let Header = class Header extends Vue {
    get isZhCN() {
        return KLOOK.getLocale() === 'zh-CN';
    }
    logout() {
        this.$ajax.get(URLS.logout).then(() => {
            this.$store.commit('user/cleanUserInfo');
            Cookie.remove('isLogin');
            this.$router.push({ name: 'login' });
        });
    }
};
__decorate([
    namespace('user').State('username')
], Header.prototype, "userName", void 0);
__decorate([
    namespace('user').State('user_type')
], Header.prototype, "userType", void 0);
Header = __decorate([
    Component({
        components: {
            KlookNav,
            KlookLangSelector,
        },
    })
], Header);
export default Header;
