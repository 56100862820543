import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let KlookIcon = class KlookIcon extends Vue {
    get styles() {
        let result = {};
        if (Array.isArray(this.size)) {
            result = {
                width: `${this.size[0]}px`,
                height: `${this.size[1]}px`,
            };
        }
        else {
            result = {
                width: `${this.size || 16}px`,
                height: `${this.size || 16}px`,
            };
        }
        return result;
    }
};
__decorate([
    Prop(String)
], KlookIcon.prototype, "type", void 0);
__decorate([
    Prop([Number, Array])
], KlookIcon.prototype, "size", void 0);
KlookIcon = __decorate([
    Component
], KlookIcon);
export default KlookIcon;
