import { __decorate } from "tslib";
import { Vue, Component, PropSync, Watch } from 'vue-property-decorator';
import { URLS } from '@/common/js/constant/index';
import { accountRules } from '@/common/js/form-rule/index';
import Cookie from 'js-cookie';
import Back from './common-back.vue';
let DrawerSetPassword = class DrawerSetPassword extends Vue {
    constructor() {
        super(...arguments);
        this.form = {
            old_password: '',
            new_password: '',
            confirm_new_password: '',
        };
        this.formRules = {};
        this.isLoading = false;
        this.isNotSetPassword = false;
    }
    onShowChanged(val) {
        if (val) {
            this.formRules = accountRules.call(null, this.form);
            this.getPasswordLogList();
        }
        else {
            const form = this.$refs.form;
            form.resetFields();
        }
    }
    getPasswordLogList() {
        this.$ajax.get(URLS.getPasswordUpdateLog)
            .then((res) => {
            this.isNotSetPassword = !res.change_log || res.change_log.length === 0;
        });
    }
    save() {
        const form = this.$refs.form;
        form.validate((valid) => {
            if (valid) {
                this.isLoading = true;
                this.$ajax.post(URLS.changePassword, {
                    new_password: KLOOK.md5(this.form.new_password),
                    old_password: this.isNotSetPassword ? null : KLOOK.md5(this.form.old_password),
                }).then(() => {
                    this.$successTips();
                    this.$store.commit('user/cleanUserInfo');
                    Cookie.remove('isLogin');
                    this.$router.push({ name: 'login' });
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        });
    }
};
__decorate([
    PropSync('show', { type: Boolean })
], DrawerSetPassword.prototype, "isShowDrawer", void 0);
__decorate([
    Watch('show')
], DrawerSetPassword.prototype, "onShowChanged", null);
DrawerSetPassword = __decorate([
    Component({
        components: {
            Back,
        },
    })
], DrawerSetPassword);
export default DrawerSetPassword;
