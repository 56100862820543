import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import RedeemVoucher from './redeem-voucher.vue';
import Foot from '@/views/_modules/mobile/footer.vue';
let Home = class Home extends Vue {
    get isZhCN() {
        return KLOOK.getLocale() === 'zh-CN';
    }
};
Home = __decorate([
    Component({
        components: {
            RedeemVoucher,
            Foot,
        },
    })
], Home);
export default Home;
