import Axios from "axios";
import router from "@/router/index";
import { Modal, message } from "ant-design-vue";
import Cookie from "js-cookie";
import { captchaInterceptor } from "@klook/captcha";
import logger from "@/common/js/logger";
const ajax = {};
ajax.install = (Vue) => {
    const axiosInstance = Axios.create({
        timeout: 1000 * 10,
    });
    captchaInterceptor(axiosInstance, {
        // 验证成功
        onSuccess: (result) => {
            logger.handleGeneralInfo({
                sub_type: 'merchant:fe:merchant_counter_web_geetest',
                err_type: "geetestSuccess",
                logquery_level: "I",
                sdk_response: result,
                api_url: result._response && result._response.config.url,
                sdk_version: result._version,
                page_url: window.location.href,
            });
        },
        onClose: (closeRes) => {
            // 关闭验证
            logger.handleGeneralInfo({
                sub_type: 'merchant:fe:merchant_counter_web_geetest',
                err_type: "geetestClose",
                logquery_level: "I",
                sdk_response: '-',
                api_url: closeRes._response && closeRes._response.config.url,
                sdk_version: closeRes._version,
                page_url: window.location.href,
            });
        },
        onFail: (failObj) => {
            // 验证码操作失败
            logger.handleGeneralInfo({
                sub_type: 'merchant:fe:merchant_counter_web_geetest',
                err_type: "geetestFail",
                logquery_level: "F",
                sdk_response: failObj,
                api_url: failObj._response && failObj._response.config.url,
                sdk_version: failObj._version,
                page_url: window.location.href,
            });
        },
        onError: (error) => {
            // 验证出错
            logger.handleGeneralInfo({
                sub_type: 'merchant:fe:merchant_counter_web_geetest',
                err_type: "geetestError",
                logquery_level: "E",
                sdk_response: error,
                api_url: error._response && error._response.config.url,
                sdk_version: error._version,
                page_url: window.location.href,
            });
        },
    });
    // 请求拦截
    axiosInstance.interceptors.request.use((config) => {
        config.headers["x-platform"] = KLOOK.isMobile ? "mobile" : "desktop";
        return config;
    });
    // 响应拦截
    axiosInstance.interceptors.response.use((response) => {
        const res = response.data;
        if (!res.success) {
            const error = res.error;
            const errorCode = error && error.code;
            // 需要登录
            if ([400, 4001, 11001].includes(+errorCode)) {
                Modal.error({
                    content: error.message || "Error",
                    onOk() {
                        Cookie.remove("isLogin");
                        router.push({ name: "login" });
                    },
                });
                return Promise.reject(""); // error传空，不用小弹窗提示
            }
            else {
                return Promise.reject(error.message);
            }
        }
        return response;
    }, (error) => {
        return Promise.reject(error);
    });
    Vue.prototype.$ajax = {
        get(url, params, isShowCommonErrorMessage = true) {
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get(url, { params })
                    .then((res) => {
                    resolve(res.data && res.data.result);
                })
                    .catch((err) => {
                    if (err) {
                        if (isShowCommonErrorMessage) {
                            message.error(err.toString());
                        }
                        reject(err);
                    }
                });
            });
        },
        post(url, params, isShowCommonErrorMessage = true) {
            return new Promise((resolve, reject) => {
                const geetest_config = params.geetest_config || {};
                delete params.geetest_config;
                axiosInstance
                    .post(url, params, {
                    ...geetest_config
                })
                    .then((res) => {
                    resolve(res.data && res.data.result);
                })
                    .catch((err) => {
                    if (err) {
                        if (isShowCommonErrorMessage) {
                            message.error(err.toString());
                        }
                        reject(err);
                    }
                });
            });
        },
    };
};
export default ajax;
