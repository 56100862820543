import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { URLS } from '@/common/js/constant/index';
import { accountRules } from '@/common/js/form-rule/index';
const loginTypeMap = {
    2: 'email',
    3: 'phone',
};
let CodeVerifier = class CodeVerifier extends Vue {
    constructor() {
        super(...arguments);
        this.formRules = {};
        this.isUsernameValid = false;
        this.verifyIntervalSeconds = 60;
        this.errorText = '';
        this.timer = '';
        this.form = {
            login_type: '',
            username: '',
            verify_code: '',
        };
        this.isLoading = false;
    }
    get isVerifyingCode() {
        return this.verifyIntervalSeconds < 60;
    }
    get remainingTips() {
        const second = this.verifyIntervalSeconds;
        return this.$t('signup.send_again', { second });
    }
    verify(checkCodeImmediately = true) {
        const form = this.$refs.form;
        return new Promise((resolve, reject) => {
            form.validate((valid) => {
                if (valid) {
                    const data = {
                        login_type: this.form.login_type,
                        username: this.form.username,
                        verify_code: this.form.verify_code,
                    };
                    if (checkCodeImmediately) {
                        this.verifyCode()
                            .then((res) => resolve({
                            ...data,
                            ...res,
                        }))
                            .catch((err) => reject(err));
                    }
                    else {
                        resolve(data);
                    }
                }
                else {
                    reject();
                }
            });
        });
    }
    onDefaultTypeChanged(val) {
        this.form.login_type = val;
        this.form.username = '';
        this.form.verify_code = '';
        this.verifyIntervalSeconds = 60;
        clearTimeout(this.timer);
        this.$nextTick(() => {
            const form = this.$refs.form;
            this.$nextTick(() => form.clearValidate());
        });
    }
    onDefaultUsernameChanged(val) {
        this.form.username = val;
    }
    onErrTextChanged(val) {
        this.errorText = val;
    }
    bindRules() {
        this.formRules = accountRules.call(null, this.form);
    }
    onFormChanged(val) {
        this.$nextTick(() => {
            const form = this.$refs.form;
            form.validateField(['username'], (err) => (this.isUsernameValid = !err));
        });
    }
    async sendVerifyCode() {
        let loginType = this.form.login_type;
        let url = '';
        switch (this.verifyType) {
            case 2:
                url = URLS.sendModifyPwdVerifyCodeByLoginType;
                break;
            case 4:
                url = URLS.sendBindVerification;
                loginType = loginTypeMap[loginType] || loginType;
                break;
            default:
                break;
        }
        const form = {
            login_type: loginType,
            verify_type: this.verifyType,
            from: this.from || 0,
            // 兼容不同接口
            login_id: this.form.username,
            username: this.form.username,
        };
        if ([2, 4].includes(this.verifyType)) {
            form.geetest_config = {
                needCaptcha: true
            };
        }
        this.isLoading = true;
        this.errorText = '';
        console.log('form', url, this.verifyType);
        this.$ajax
            .post(url, form, false)
            .then((res) => {
            this.$message.success(this.$t('31102'));
            this.countingDown();
        })
            .catch((err) => {
            this.errorText = err;
        })
            .finally(() => {
            this.isLoading = false;
        });
    }
    // 倒计时
    countingDown() {
        if (this.verifyIntervalSeconds > 0) {
            this.timer = setTimeout(() => {
                this.verifyIntervalSeconds--;
                this.countingDown();
            }, 1000);
        }
        else {
            this.verifyIntervalSeconds = 60;
        }
    }
    cleanError() {
        this.errorText = '';
    }
    verifyCode() {
        return new Promise(async (resolve, reject) => {
            this.$ajax
                .post(URLS.verifyCode, {
                code: this.form.verify_code,
                login_id: this.form.username,
                login_type: this.form.login_type,
                verify_type: this.verifyType,
                from: this.from || 0,
            }, false)
                .then((res) => resolve(res || {}))
                .catch((err) => {
                this.errorText = err;
                reject(err);
            });
        });
    }
    created() {
        this.bindRules();
    }
};
__decorate([
    Prop({ type: Number, default: 2 })
], CodeVerifier.prototype, "defaultType", void 0);
__decorate([
    Prop({ type: Number, default: 1 })
], CodeVerifier.prototype, "verifyType", void 0);
__decorate([
    Prop({ type: String, default: '' })
], CodeVerifier.prototype, "defaultUsername", void 0);
__decorate([
    Prop({ type: String, default: '' })
], CodeVerifier.prototype, "errText", void 0);
__decorate([
    Prop({ type: Number, default: NaN })
], CodeVerifier.prototype, "from", void 0);
__decorate([
    Prop({ type: String, default: 'forget_password' })
], CodeVerifier.prototype, "captchaAction", void 0);
__decorate([
    Watch('defaultType', { immediate: true })
], CodeVerifier.prototype, "onDefaultTypeChanged", null);
__decorate([
    Watch('defaultUsername', { immediate: true })
], CodeVerifier.prototype, "onDefaultUsernameChanged", null);
__decorate([
    Watch('errText')
], CodeVerifier.prototype, "onErrTextChanged", null);
__decorate([
    Watch('form.username', { deep: true, immediate: true })
], CodeVerifier.prototype, "onFormChanged", null);
CodeVerifier = __decorate([
    Component
], CodeVerifier);
export default CodeVerifier;
