import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import KlookLangSelector from '../../desktop/lang-selector.vue';
let LayoutLogin = class LayoutLogin extends Vue {
};
LayoutLogin = __decorate([
    Component({
        components: {
            KlookLangSelector,
        },
    })
], LayoutLogin);
export default LayoutLogin;
