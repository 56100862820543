import { __decorate } from "tslib";
import { Vue, Component, Watch } from 'vue-property-decorator';
import { URLS } from '@/common/js/constant/index';
let VerifyToken = class VerifyToken extends Vue {
    constructor() {
        super(...arguments);
        this.status = 'loading'; // loading、success、fail
        this.seconds = 3;
        this.tokenInfo = {
            token: '',
            token_type: '',
            login_type: '',
            username: '',
        };
    }
    get isZhCN() {
        return KLOOK.getLocale() === 'zh-CN';
    }
    get verifyDes() {
        const second = this.seconds;
        return this.$t('verify_token.jump_tips', { time: second });
    }
    onStatusChanged(val) {
        if (['success', 'fail'].includes(val)) {
            this.countingDown();
        }
    }
    created() {
        const token = this.$route.query.token;
        if (!token) {
            this.status = 'fail';
            return;
        }
        this.tokenInfo.token = token;
        this.verifyToken();
    }
    // 倒计时
    countingDown() {
        if (this.seconds > 0) {
            setTimeout(() => {
                this.seconds--;
                this.countingDown();
            }, 1000);
        }
        else {
            if (this.status === 'success') {
                this.$router.push({
                    name: 'setPassword',
                    query: this.tokenInfo,
                });
            }
            else {
                this.$router.push({ name: 'login' });
            }
        }
    }
    // 验证token
    verifyToken() {
        this.$ajax.get(URLS.verifyToken, {
            token: this.tokenInfo.token,
        }, false).then((res) => {
            Object.assign(this.tokenInfo, res);
            this.status = 'success';
        }).catch(() => {
            this.status = 'fail';
        });
    }
};
__decorate([
    Watch('status')
], VerifyToken.prototype, "onStatusChanged", null);
VerifyToken = __decorate([
    Component
], VerifyToken);
export default VerifyToken;
